<template>
  <div style="width: 97%">
    <a-modal
      :dialog-style="{
        top: '10%',
      }"
      :title="
        editMode ? $t('calender.editEventTitle') : $t('calender.addEventTitle')
      "
      :visible="visibleModalEvent"
      width="75%"
      @cancel="handleCancelModalEvent"
      :footer="null"
    >
      <a-form
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 20 }"
        @submit="
          (e) => {
            if (editMode) handleEditEvent(e);
            else handleAddEvent(e);
          }
        "
      >
        <a-form-item :label="$t('actualite.titre')">
          <a-input
            :placeholder="$t('actualite.titre')"
            v-decorator="[
              'title',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.titre'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item :label="$t('assiduite.date')">
          <a-range-picker
            :show-time="{
              hideDisabledOptions: true,
              defaultValue: [
                moment('HH:mm:ss'),
                moment('HH:mm:ss').add(1, 'hours'),
              ],
            }"
            format="DD/MM/YYYY HH:mm:ss"
            style="width: 600px"
            v-decorator="[
              'date',
              {
                rules: [
                  {
                    required: true,
                    message: $t('paiement.selectionnerDate'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item :label="$t('calender.eventType')">
          <a-select
            :placeholder="$t('calender.eventType')"
            @change="
              (e) => {
                handleTypeChange(e);
              }
            "
            v-decorator="[
              'type',
              {
                rules: [
                  {
                    required: true,
                    message: $t('calender.typeRequired'),
                  },
                ],
              },
            ]"
          >
            <a-select-option value="exam">
              {{ $t("calender.exam") }}
            </a-select-option>
            <a-select-option value="party">
              {{ $t("calender.party") }}
            </a-select-option>
            <a-select-option value="excursion">
              {{ $t("calender.excursion") }}
            </a-select-option>
            <a-select-option value="meeting">
              {{ $t("calender.meeting") }}
            </a-select-option>
            <a-select-option value="other">
              {{ $t("calender.other") }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="$t('calender.color')">
          <input
            class="color-picker"
            type="color"
            :value="selectedColor"
            :v-model="selectedColor"
            @change="
              (e) => {
                selectedColor = e.target.value;
              }
            "
          />
        </a-form-item>

        <a-form-item :label="$t('actualite.cibles')">
          <a-select
            mode="multiple"
            @change="
              (val) => {
                if (!val.includes('parents')) selectedStudents = [];
              }
            "
            v-decorator="[
              'target',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.cible'),
                  },
                ],
              },
            ]"
          >
            <a-select-option value="all">
              {{ $t("actualite.tous") }}
            </a-select-option>
            <a-select-option value="teachers">
              {{ $t("actualite.enseignants") }}
            </a-select-option>
            <a-select-option value="students">
              {{ $t("actualite.eleves") }}
            </a-select-option>
            <a-select-option value="administration">
              {{ $t("actualite.administration") }}
            </a-select-option>
            <a-select-option value="parents">
              {{ $t("actualite.parents") }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <div
          v-if="
            form.getFieldValue('target') &&
            form.getFieldValue('target').includes('parents')
          "
          style="text-align: center"
        >
          <a-tag color="blue" class="mb-4">{{
            $t("actualite.specifiedInfo")
          }}</a-tag>
          <a-form-item :label="$t('actualite.specificFor')">
            <a-select
              @change="
                () => {
                  selectedStudents = [];
                }
              "
              v-decorator="[
                'specificFor',
                {
                  initialValue: 'all',
                  rules: [{ required: true }],
                },
              ]"
            >
              <a-select-option
                value="all"
                @change="
                  () => {
                    selectedStudents = [];
                  }
                "
              >
                {{ $t("actualite.tous") }}
              </a-select-option>
              <a-select-option value="students">
                {{ $t("actualite.students") }}
              </a-select-option>
              <a-select-option value="levels">
                {{ $t("actualite.levels") }}
              </a-select-option>
              <a-select-option value="classrooms">
                {{ $t("actualite.classrooms") }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="
              form.getFieldValue('specificFor') &&
              form.getFieldValue('specificFor') == 'levels'
            "
            :label="$t('actualite.levels')"
          >
            <a-select
              :placeholder="$t('enseignant.listeNiveaux')"
              mode="multiple"
              v-decorator="[
                'levels',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('enseignant.niveauxRequis'),
                    },
                  ],
                },
              ]"
            >
              <a-select-option value="1">
                {{ $t("niveau.niveau1") }}
              </a-select-option>
              <a-select-option value="2">
                {{ $t("niveau.niveau2") }}
              </a-select-option>
              <a-select-option value="3">
                {{ $t("niveau.niveau3") }}
              </a-select-option>
              <a-select-option value="4">
                {{ $t("niveau.niveau4") }}
              </a-select-option>
              <a-select-option value="5">
                {{ $t("niveau.niveau5") }}
              </a-select-option>
              <a-select-option value="6">
                {{ $t("niveau.niveau6") }}
              </a-select-option>
              <a-select-option value="7">
                {{ $t("niveau.niveau7") }}
              </a-select-option>
              <a-select-option value="8">
                {{ $t("niveau.niveau8") }}
              </a-select-option>
              <a-select-option value="9">
                {{ $t("niveau.niveau9") }}
              </a-select-option>
              <a-select-option value="10">
                {{ $t("niveau.niveau10") }}
              </a-select-option>
              <a-select-option value="11">
                {{ $t("niveau.niveau11") }}
              </a-select-option>
              <a-select-option value="12">
                {{ $t("niveau.niveau12") }}
              </a-select-option>
              <a-select-option value="13">
                {{ $t("niveau.niveau13") }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="
              form.getFieldValue('specificFor') &&
              form.getFieldValue('specificFor') == 'classrooms'
            "
            :label="$t('actualite.classrooms')"
          >
            <a-select
              :placeholder="$t('actualite.classrooms')"
              mode="multiple"
              v-decorator="[
                'classrooms',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('choisir.classe'),
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="classe in classes"
                :key="classe._id"
                :value="classe._id"
              >
                {{ classe.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="
              form.getFieldValue('specificFor') &&
              form.getFieldValue('specificFor') == 'students'
            "
            :label="$t('actualite.students')"
          >
            <multiselect
              v-model="selectedStudents"
              :placeholder="$t('sms.selectionnerEleves')"
              :selectLabel="$t('sms.pressEnterToSelect')"
              :options="studentList"
              :custom-label="customLabel"
              :close-on-select="false"
              :preserve-search="true"
              :maxHeight="700"
              label="_id"
              track-by="_id"
              :preselect-first="false"
              :multiple="true"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="align-text-top">
                    {{ props.option.lastName + " " + props.option.firstName }}
                  </span>
                  <br /><span class="option__small font-size-12 text-warning"
                    >Parent: {{ props.option.parent.father.firstName }}
                    {{ props.option.parent.father.lastName }} et
                    {{ props.option.parent.mother.firstName }}
                    {{ props.option.parent.mother.lastName }}</span
                  >
                </div>
              </template>
            </multiselect>
          </a-form-item>
        </div>
        <a-form-item :label="$t('actualite.description')">
          <quill-editor v-model="description" :options="editorOptions" />
        </a-form-item>

        <a-divider></a-divider>
        <div class="d-flex justify-content-end">
          <a-button
            class="ml-2 mr-2"
            key="back"
            @click="handleCancelModalEvent"
          >
            {{ $t("action.annuler") }}
          </a-button>
          <a-button
            v-if="!editMode"
            class="ml-2 mr-2"
            key="submit"
            htmlType="submit"
            :loading="loading"
            :disabled="loading"
            type="primary"
          >
            {{ $t("calender.addEvent") }}
          </a-button>
          <a-button
            v-else
            class="ml-2 mr-2"
            key="submit"
            htmlType="submit"
            :loading="loading"
            :disabled="loading"
            type="primary"
          >
            {{ $t("calender.editEvent") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>
    <a-drawer
      width="640"
      placement="right"
      :closable="true"
      :visible="visibleEventDetails"
      @close="handleCancelEventDetails"
    >
      <h2 v-if="!selectedEvent.isMeetingModel">
        <span v-if="selectedEvent.type != 'other'">
          ({{ $t("calender." + selectedEvent.type) }})
        </span>
        {{ selectedEvent.title }}
      </h2>
      <h2 v-else>
        <span> ({{ $t("calender.meeting") }}) </span>
        {{ selectedEvent.title }}
      </h2>
      <a-divider />
      <fieldset>
        <p :style="pStyle">{{ $t("assiduite.date") }}</p>
        <div class="row">
          <div class="col-md-6">
            <strong>{{ $t("all.startDate") }}:</strong>
            {{ moment(selectedEvent.start).format("DD/MM/YYYY HH:mm") }}
          </div>
          <div class="col-md-6">
            <strong>{{ $t("all.endDate") }}:</strong>
            {{ moment(selectedEvent.end).format("DD/MM/YYYY HH:mm") }}
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <strong>{{ $t("calender.duration") }}:</strong>
            {{
              moment
                .duration(
                  moment(selectedEvent.end).diff(moment(selectedEvent.start))
                )
                .format({
                  userLocale: moment.locale("fr-tn"),
                })
            }}
          </div>
        </div>
      </fieldset>

      <a-divider />
      <fieldset v-if="!selectedEvent.isMeetingModel">
        <p :style="pStyle">{{ $t("profil.informations") }}</p>
        <div class="row">
          <div class="col-md-6">
            <strong class="mr-2">{{ $t("actualite.cibles") }}:</strong>
            <span v-for="target in selectedEvent.target" :key="target">
              <a-tag color="blue">{{
                $t("actualite." + (target == "all" ? "tous" : target))
              }}</a-tag>
            </span>
          </div>
          <div
            class="col-md-6"
            v-if="
              selectedEvent.students ||
              selectedEvent.levels ||
              selectedEvent.classrooms
            "
          >
            <strong class="mr-2">{{ $t("actualite.specificFor") }}:</strong>
            <span v-if="selectedEvent.students">
              <a-tag
                v-for="student in selectedEvent.students"
                :key="student"
                color="green"
                >{{ getStudentFullname(student) }}</a-tag
              >
            </span>
            <span v-else-if="selectedEvent.classrooms">
              <a-tag
                v-for="classroom in selectedEvent.classrooms"
                :key="classroom"
                color="green"
                >{{ getClassroomName(classroom) }}</a-tag
              >
            </span>
            <span v-else-if="selectedEvent.levels">
              <a-tag
                v-for="level in selectedEvent.levels"
                :key="level"
                color="green"
                >{{ $t("niveau.niveau" + level) }}</a-tag
              >
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 d-flex">
            <strong class="mr-2">{{ $t("calender.color") }}:</strong>
            <div
              class="card"
              :style="{
                backgroundColor: selectedEvent.color,
                height: '20px',
                width: '80px',
              }"
            ></div>
          </div>
        </div>
      </fieldset>
      <fieldset v-else>
        <p :style="pStyle">{{ $t("profil.informations") }}</p>
        <div class="row">
          <div class="col-md-6">
            <strong class="mr-12">{{ $t("etablissement.type") }}:</strong>
            <a-tag color="cyan">{{
              $t("calender.meetingBetweenTeachersAdvisors")
            }}</a-tag>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2" v-if="selectedEvent.levels">
            <strong class="mr-2">{{ $t("actualite.specificFor") }}:</strong>
            <span if="selectedEvent.levels">
              <a-tag
                v-for="level in selectedEvent.levels"
                :key="level"
                color="green"
                >{{ $t("niveau.niveau" + level) }}</a-tag
              >
            </span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <strong class="mr-12 mr-2">{{ $t("calender.createdBy") }}:</strong>
            <a-tag color="purple">{{ this.selectedEvent.createdBy }}</a-tag>
          </div>
        </div>
      </fieldset>
      <a-divider />
      <fieldset>
        <p :style="pStyle">{{ $t("actualite.description") }}</p>
        <div class="row">
          <div class="col-md-12 m-auto">
            <div
              class="m-auto"
              v-if="selectedEvent.desc"
              v-html="selectedEvent.desc"
            ></div>
            <a-tag class="m-auto" v-else>Il n'y a pas de description</a-tag>
          </div>
        </div>
      </fieldset>
      <div v-if="!selectedEvent.isMeetingModel">
        <div
          :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
        >
          <a-button
            style="margin-right: 8px"
            type="primary"
            @click="openEditModal"
          >
            {{ $t("all.edit") }}
          </a-button>
          <a-button
            type="danger"
            :loading="loading"
            :disabled="loading"
            @click="handleDeleteEvent"
          >
            {{ $t("action.supprimer") }}
          </a-button>
        </div>
      </div>
    </a-drawer>
    <template v-if="loadingCalender">
      <a-row>
        <a-col :span="16" :offset="11">
          <a-spin style="margin-top: 35vh" size="large" />
        </a-col>
      </a-row>
    </template>
    <div v-else>
      <FullCalendar
        ref="calendar"
        class="demo-app-calendar"
        :options="calendarOptions"
      >
      </FullCalendar>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import moment from "moment";
const momentDurationFormatSetup = require("moment-duration-format");

momentDurationFormatSetup(moment);

export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: { FullCalendar, quillEditor },
  data() {
    return {
      loadingCalender: true,
      selectedStudents: [],
      moment,
      editMode: false,
      pStyle: {
        fontSize: "16px",
        color: "rgba(0,0,0,0.85)",
        lineHeight: "24px",
        display: "block",
        marginBottom: "16px",
      },
      pStyle2: {
        marginBottom: "24px",
      },
      visibleEventDetails: false,
      selectedEvent: {},
      disabledDate: false,
      description: "",
      studentList: [],
      events: [],
      meetings: [],
      defaultColor: "#2a63f3",
      selectedColor: "#2a63f3",
      classes: [],
      teachers: [],
      loading: false,
      visibleModalEvent: false,
      form: this.$form.createForm(this),
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "addEventButton dayGridMonth,timeGridWeek,timeGridDay",
        },
        //  initialDate: moment().startOf("day"),
        locale: frLocale,
        navLinks: true, // can click day/week names to navigate views
        selectable: true,
        selectMirror: true,

        views: {
          dayGridMonth: {
            buttonText: this.$t("calender.month"),
            dayHeaderFormat: {
              weekday: "long",
            },
          },
          timeGridWeek: { buttonText: this.$t("calender.week") },
          timeGridDay: { buttonText: this.$t("calender.day") },
        },

        customButtons: {
          addEventButton: {
            text: "+  " + this.$t("calender.addEvent"),
            click: () => {
              this.visibleModalEvent = true;
              this.editMode = false;
            },
          },
        },

        editable: true,
        dayMaxEvents: true, // allow "more" link when too many events
        events: this.getEventData,
        dateClick: this.dateClick,
        eventDrop: this.dropEvent,
        eventClick: this.handleEventClick,
      },
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ align: [] }],

            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],

            [{ size: ["small", false, "large", "huge"] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }],
            [{ font: [] }],

            // ["clean"],
          ],
        },
      },
    };
  },

  async created() {
    await this.fetchEvents();

    await apiClient
      .post("/students/filter", {
        query: {
          status: "active",
        },
      })
      .then(({ data }) => {
        this.studentList = data;
      });

    this.loadingCalender = false;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));

    await apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
      })
      .then((res) => {
        this.teachers = res.data.map((d) => {
          return {
            _id: d._id,
            firstName: d.employee.firstName,
            lastName: d.employee.lastName,
          };
        });
      });
  },

  methods: {
    customLabel({ firstName, lastName }) {
      return `${lastName} ${firstName}`;
    },
    async fetchEvents() {
      if (this.$refs.calendar) this.$refs.calendar.getApi().refetchEvents();
      await apiClient
        .post("/events/filter", {
          query: {},
        })
        .then(({ data }) => {
          this.events = data;
          this.$refs.calendar.getApi().refetchEvents();
        })
        .catch((e) => {
          console.log(e);
        });
      await apiClient
        .post("/events/meetings/filter", {
          query: {},
        })
        .then(({ data }) => {
          this.meetings = data;
          this.$refs.calendar.getApi().refetchEvents();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleTypeChange(type) {
      if (type == "exam") this.selectedColor = "#CD1AFF";
      else if (type == "party") this.selectedColor = "#f3b760";
      else if (type == "excursion") this.selectedColor = "#6bff6e";
      else if (type == "meeting") this.selectedColor = "#F76060";
      else if (type == "other") this.selectedColor = this.defaultColor;
    },
    handleCancelModalEvent() {
      this.visibleModalEvent = false;
      this.selectedColor = this.defaultColor;
      this.description = "";
      this.disabledDate = false;
      this.editMode = false;
      this.form.resetFields();
    },
    handleAddEvent(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loading = true;

          const data = {};

          data.desc = this.description;
          data.color = this.selectedColor;
          data.title = values.title;
          data.target = values.target;
          data.type = values.type;

          data.start = values.date[0];
          data.end = values.date[1];

          if (values.specificFor === "students") {
            data.students = [];
            for (const student of this.selectedStudents) {
              data.students.push(student._id);
            }
          } else if (values.specificFor === "levels") {
            data.levels = values.levels;
          } else if (values.specificFor === "classrooms") {
            data.classrooms = values.classrooms;
          }

          apiClient({
            method: "put",
            url: "/events",
            data,
          })
            .then((res) => {
              this.$message.success(this.$t("calender.addEvent"));
              this.$emit("newsAdded", res.data);
              this.handleCancelModalEvent();
              this.fetchEvents();
            })
            .catch((e) => this.$message.error(this.$t("error.impoPubAjout")))
            .finally(() => (this.loading = false));
        }
      });
    },
    handleEditEvent(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loading = true;

          const data = {};

          data.desc = this.description;
          data.color = this.selectedColor;
          data.title = values.title;
          data.target = values.target;
          data.type = values.type;

          data.start = values.date[0];
          data.end = values.date[1];

          if (values.specificFor === "students") {
            data.students = [];
            for (const student of this.selectedStudents) {
              data.students.push(student._id);
            }
          } else {
            data.students = null;
          }
          if (values.specificFor === "levels") {
            data.levels = values.levels;
          } else {
            data.levels = null;
          }
          if (values.specificFor === "classrooms") {
            data.classrooms = values.classrooms;
          } else {
            data.classrooms = null;
          }

          for (const key in data) {
            this.selectedEvent[key] = data[key];
          }

          apiClient({
            method: "patch",
            url: "/events/" + this.selectedEvent._id,
            data,
          })
            .then((res) => {
              this.$message.success(this.$t("calender.editedEvent"));
              this.handleCancelModalEvent();
              this.fetchEvents();
            })
            .catch((e) => this.$message.error(this.$t("error.modif")))
            .finally(() => (this.loading = false));
        }
      });
    },
    handleDeleteEvent() {
      this.loading = true;
      apiClient({
        method: "delete",
        url: "/events/" + this.selectedEvent._id,
      })
        .then((res) => {
          this.$message.success(this.$t("calender.deletedEvent"));
          this.handleCancelModalEvent();
          this.events = this.events.filter(
            (event) => event._id != this.selectedEvent._id
          );
          this.fetchEvents();
          this.handleCancelEventDetails();
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.supp"));
        })
        .finally(() => (this.loading = false));
    },
    dateClick(e) {
      this.disabledDate = true;
      this.date = [
        moment(e.date).add(8, "hours"),
        moment(e.date).add(9, "hours"),
      ];
      this.form.setFields({
        date: {
          value: [
            moment(e.date).add(8, "hours"),
            moment(e.date).add(9, "hours"),
          ],
        },
      });
      this.visibleModalEvent = true;
    },
    getEventData(e, successCallback) {
      const data = [];
      this.events.forEach((event) => {
        data.push({
          _id: event._id,
          model: "event",
          title: `${
            event.type != "other"
              ? "(" + this.$t("calender." + event.type) + ") "
              : ""
          } ${event.title}`,
          start: moment(event.start).format("YYYY-MM-DD[T]HH:mm"),
          end: moment(event.end).format("YYYY-MM-DD[T]HH:mm"),
          description: event.desc,
          className: "fc-event-danger fc-event-solid-warning",
          color: event.color,
          background: event.color,
        });
      });
      this.meetings.forEach((meeting) => {
        data.push({
          _id: meeting._id,
          model: "meeting",
          title: `(${this.$t("calender.meeting")})  ${meeting.topic}`,
          start: moment(meeting.date).format("YYYY-MM-DD[T]HH:mm:ss"),
          editable: false,
          end: moment(
            new Date(meeting.date).getTime() + 1000 * 60 * 60 * meeting.duration
          ).format("YYYY-MM-DD[T]HH:mm:ss"),
          description: meeting.desc,
          className: "fc-event-danger fc-event-solid-warning",
          color: "#23ced1",
        });
      });
      successCallback(data);
    },
    handleEventClick(e) {
      const key = e.event._def.extendedProps._id;
      if (e.event._def.extendedProps.model == "event") {
        this.selectedEvent = this.events.find((event) => event._id == key);
        this.selectedEvent.isMeetingModel = false;
      } else if (e.event._def.extendedProps.model == "meeting") {
        this.selectedEvent = this.meetings.find((event) => event._id == key);
        this.selectedEvent.isMeetingModel = true;
        this.selectedEvent.title = this.selectedEvent.topic;
        this.selectedEvent.start = this.selectedEvent.date;
        this.selectedEvent.end = moment(
          new Date(this.selectedEvent.date).getTime() +
            1000 * 60 * 60 * this.selectedEvent.duration
        ).format("YYYY-MM-DD[T]HH:mm:ss");
        this.selectedEvent.createdBy = "--";
        const advisor = this.teachers.find(
          (t) => t._id == this.selectedEvent.advisor
        );
        if (advisor) {
          this.selectedEvent.createdBy =
            advisor.firstName + " " + advisor.lastName;
        }
      }
      this.visibleEventDetails = true;
    },
    handleCancelEventDetails() {
      this.visibleEventDetails = false;
    },
    openEditModal() {
      this.visibleModalEvent = true;
      this.editMode = true;
      this.visibleEventDetails = false;

      setTimeout(() => {
        let specificFor = "all";
        if (this.selectedEvent.students) specificFor = "students";
        else if (this.selectedEvent.levels) specificFor = "levels";
        else if (this.selectedEvent.classrooms) specificFor = "classrooms";
        this.form.setFieldsValue({
          title: this.selectedEvent.title,
          date: [
            moment(this.selectedEvent.start),
            moment(this.selectedEvent.end),
          ],
          type: this.selectedEvent.type,
          target: this.selectedEvent.target,
        });
        setTimeout(() => {
          this.form.setFieldsValue({
            specificFor: specificFor,
          });
          setTimeout(() => {
            this.form.setFieldsValue({
              levels: this.selectedEvent.levels,
              students: this.selectedEvent.students,
              classrooms: this.selectedEvent.classrooms,
            });
          }, 100);
        }, 100);

        this.selectedColor = this.selectedEvent.color;
        this.description = this.selectedEvent.desc;
        this.selectedstudents = this.selectedEvent.students;
      }, 200);
    },
    dropEvent(e) {
      const event = e.event;

      this.events.map((ev) => {
        if (ev._id == event._def.extendedProps._id) {
          ev.start = event.start;
          ev.end = event.end;
        }
        return ev;
      });
      apiClient
        .patch("/events/" + event._def.extendedProps._id, {
          start: event.start,
          end: event.end,
        })
        .then(() => {
          this.$message.success(this.$t("calender.editedEvent"));

          this.fetchEvents();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getStudentFullname(id) {
      const student = this.studentList.find((s) => s._id == id);
      if (student) return student.firstName + " " + student.lastName;
    },
    getClassroomName(id) {
      const classe = this.classes.find((s) => s._id == id);
      if (classe) return classe.name;
    },
  },
};
</script>

<style>
.ql-editor {
  height: 100px;
}

.color-picker {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
  height: 30px;
  width: 200px;
  border: solid 1px #ccc;
  border-radius: 5px;
}
</style>

<style lang="scss">
.fc .fc-button {
  padding: 0.5rem 1.25rem;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0.475rem;
  vertical-align: middle;
  font-weight: 500;
  text-transform: capitalize;

  --fc-button-bg-color: #4b7cf3;
  --fc-button-border-color: #4b7cf3;
  --fc-button-hover-bg-color: #78a3ff;
  --fc-button-hover-border-color: #78a3ff;
  --fc-button-active-bg-color: #2a63f3;
  --fc-button-active-border-color: #2a63f3;
}

.fc {
  table {
    font-size: 18px;
  }

  // More link
  .fc-daygrid-more-link {
    font-weight: bold;
  }

  // Slot
  .fc-timegrid-slot {
    height: 2rem;
    font-size: 0.95rem;
  }

  // List
  .fc-list-day-cushion,
  .fc-list-table td {
    padding: 0.85rem 1.15rem;
  }

  .fc-list-day-text,
  .fc-list-day-side-text {
    font-size: 1.1rem;
    color: #212121;
    font-weight: bold;
  }

  .fc-list,
    //.fc-scroller,
    .fc-list-table {
    border-radius: 0.475rem;
  }

  .fc-list {
    overflow: hidden;
    position: relative;
  }

  // Week
  .fc-timegrid-axis {
    padding-left: 0rem;
    padding-right: 0;
  }

  // Main
  .fc-timegrid-event .fc-event-main {
    padding: 0.25rem 0.25rem;
  }

  // Indicator arrow
  .fc-timegrid-now-indicator-arrow {
    margin-top: -1px;
  }

  // Button group
  .fc-button-group {
    .fc-button {
      margin: 0 !important;
    }
  }

  // Toolbar title
  .fc-toolbar-title {
    font-size: 1.5rem;
    font-weight: bolder;
  }

  .fc-col-header-cell {
    padding: 0.75rem 0.5rem;

    .fc-col-header-cell-cushion {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  .fc-toolbar-title {
    font-size: 1.5rem;
    font-weight: bolder;
  }

  .fc-timegrid-axis-cushion {
    font-size: 12px;
  }
}
</style>
